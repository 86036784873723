import { unpack } from "msgpackr/unpack";

export class Message {
  static create(clientId: string, message: string | Buffer): string | Buffer {
    return message;
  }

  static async parse<MessageBatch>(
    messageFrame: Blob | string
  ): Promise<[string, string | MessageBatch]> {
    if (typeof messageFrame === "string") {
      return ["clientId", messageFrame] as [string, string];
    }

    const unpackedMessageBatch = unpack(
      new Uint8Array(await messageFrame.arrayBuffer())
    );

    try {
      return JSON.parse(unpackedMessageBatch) as [string, MessageBatch];
    } catch (e) {
      // Not a JSON string, so return the raw message
      return ["hmmClientId", unpackedMessageBatch] as [string, MessageBatch];
    }
  }
}
